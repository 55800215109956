import { internalApi } from '@/api/axios.ts'

export type CalculatorResponse = {
  yearly: {
    energySources: {
      solar: {
        totalProduction: number
        totalUsed: number
        modulesNumber: number
        usage: {
          toConsumption: number
          toBattery: number
          toGpuNetwork: number
          toGrid: number
        }
      }
      grid: {
        totalUsed: number
        rate: number
        totalCost: number
        usage: {
          toConsumption: number
          toBattery: number
          toGpuNetwork: number
        }
      }
      battery: {
        capacity: number
        throughput: number
        usage: {
          toConsumption: number
          toGpuNetwork: number
          toGrid: number
        }
      }
    }
    profits: {
      billSavings: {
        totalConsumption: number
        totalCost: number
        previousCost: number
        profit: number
      }
      gpuRent: {
        totalConsumption: number
        totalGridCost: number
        activeHours: number
        rate: number
        profit: number
      }
      storageRent: {
        allocatedSpace: number
        rate: {
          value: number
          unit: string
        }
        profit: number
      }
      sellingEnergy: {
        totalEnergySold: number
        rate: number
        profit: number
      }
      totalProfit: number
    }
  }
}

export type CalculatorParamsSchema = {
  address: string
  monthlyBill: string
  ratePerKw: number
  solar: boolean
  battery: {
    capacity: number
    batteriesNumber: number
  }
  gpu: {
    modelName: string
    cardsNumber: number
    rentHourlyRate: number
    rentProbability: number
  }
  profitSources: {
    electricBillSavings: boolean
    gpuRent: boolean
    storageRent: boolean
    sellingEnergy: boolean
  }
}

export const computeCalculator = async (calculatorForm: CalculatorParamsSchema) => {
  return internalApi.post<CalculatorResponse>('forecaster/calculator/forecast', calculatorForm)
}
