import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ProfitSources from './ProfitSources'
import EstimatedSystem from './EstimatedSystem'
import { Container, OptionTitle } from './CommonCalculator'
import { Button } from '@/uiComponents/button/Button'
import { useGoogleMapsLoader } from '@/useGoogleMapsLoader'
import { useProfile } from '@/profile/useProfle'
import { AddresInput } from '@/uiComponents/AddressInput'
import { Oval } from 'react-loader-spinner'
import { AverageElectricBill } from '@/uiComponents/AverageElectricBill'
import { StyledSubtitle } from '@/uiComponents/CommonComponents'
import InputNumber from '@/uiComponents/InputNumber'
import ToggleOption from './ToggleOption'
import Counter from '@/uiComponents/Counter'
import AvaNodeCard from './AvaNodeCard'
import { Error } from '@/installationRequest/CommonForm'
import { calculatorSchema, ValidationError } from './validateCalculator'
import { CalculatorParamsSchema, CalculatorResponse, computeCalculator } from './computeCalculatorApi'
import { toast } from 'react-toastify'

const CalculatorForm = styled.form`
  display: flex;
  overflow: hidden;
  max-width: 100%;
  flex-direction: column;
  gap: 43px;
  padding-inline: 10px;

  @media (min-width: 464px) {
    padding: 60px 20px;
  }
`

const FieldContainer = styled(Container)`
  @media (min-width: 984px) {
    width: 350px;
  }

  @media (min-width: 1248px) {
    width: 400px;
  }

  @media (min-width: 1440px) {
    width: 500px;
  }
`

const BatteryContainer = styled(FieldContainer)`
  flex-direction: row;
  align-items: center;
  gap: 30px;
`

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);

  @media (min-width: 320px) and (max-width: 983px) {
    grid-template-columns: 1fr;
  }
`

const InputName = styled(StyledSubtitle)`
  margin-bottom: 12px;

  @media (min-width: 320px) and (max-width: 983px) {
    margin-top: 43px;
  }
`

const NumberOfBatteries = styled(StyledSubtitle)`
  margin-top: 12px;
`

const gpuModels: Pick<GPUSettings, 'modelName' | 'rentProbability' | 'rentHourlyRate'>[] = [
  {
    modelName: 'Nvidia 4090 24G',
    rentProbability: 0.3,
    rentHourlyRate: 0.45,
  },
  {
    modelName: 'Nvidia RTX 5080 24G',
    rentProbability: 0.3,
    rentHourlyRate: 0.45,
  },
  {
    modelName: 'Nvidia RTX 5000 ADA',
    rentProbability: 0.3,
    rentHourlyRate: 1.0,
  },
]

export type GPUSettings = {
  modelName: string
  cardsNumber: number
  rentProbability: number
  rentHourlyRate: number
}

const Calculator = () => {
  const { profile, refreshProfile, isLoading } = useProfile()
  const addressRef = useRef<HTMLDivElement>(null)
  const monthlyBillRef = useRef<HTMLDivElement>(null)
  const { isLoaded } = useGoogleMapsLoader()
  const [address, setAddress] = useState<string>(profile?.address ?? '')
  const [monthlyBill, setMonthlyBill] = useState<string>('')
  const [errors, setErrors] = useState<ValidationError>({})
  const [batteryCount, setBatteryCount] = useState<number>(1)
  const [rentPerKW, setRentPerKW] = useState<number>(0.26)
  const [period, setPeriod] = useState<'monthly' | 'yearly'>('monthly')
  const [gpuSettings, setGpuSettings] = useState<GPUSettings>({
    modelName: gpuModels[0].modelName,
    cardsNumber: 1,
    rentProbability: (gpuModels[0]?.rentProbability ?? 0.3) * 100,
    rentHourlyRate: gpuModels[0]?.rentHourlyRate,
  })
  const [profitsSources, setProfitsSources] = useState({
    electricBillSavings: true,
    sellingEnergy: true,
    storageRent: true,
    gpuRent: true,
  })
  const [yearlyProfits, setYearlyProfits] = useState<CalculatorResponse>()
  const [isCalculatorLoading, setIsCalculatorLoading] = useState<boolean>(false)

  const scrollToError = useCallback((errors: ValidationError) => {
    if (errors['address']) {
      addressRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
      return
    }
    if (errors['monthlyBill']) {
      monthlyBillRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }, [])

  useEffect(() => {
    document.title = 'Calculator'
    void refreshProfile()
  }, [refreshProfile])

  useEffect(() => {
    if (profile?.monthly_electric_bill?.amount !== undefined) {
      setMonthlyBill(profile.monthly_electric_bill.amount.toString())
    }

    if (profile?.address) {
      setAddress(profile.address)
    }
  }, [profile])

  const handleAddressSelect = useCallback((value: string) => {
    setAddress(value)
  }, [])

  const handleMonthlyBillSelect = useCallback((value: string) => {
    setMonthlyBill(value)
  }, [])

  const handleRentkwChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setRentPerKW(parseFloat(value))
  }, [])

  const handleBatteryChange = useCallback((count: number) => {
    setBatteryCount(count)
  }, [])

  const sendCalculator = async (calculatorForm: CalculatorParamsSchema) => {
    setIsCalculatorLoading(true)
    try {
      const { data, status } = await computeCalculator(calculatorForm)

      if (status === 200) {
        setYearlyProfits(data)
      }
    } catch (apiError) {
      console.log(apiError)
      toast.error('An error occurred while calculating the result. Please try again.')
    } finally {
      setIsCalculatorLoading(false)
    }
  }

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const { error } = calculatorSchema.validate(
        {
          address,
          monthlyBill,
        },
        { abortEarly: false }
      )

      if (error) {
        const validationErrors: ValidationError = {}
        error.details.forEach((detail) => {
          validationErrors[detail.path[0]] = detail.message
        })
        setErrors(validationErrors)
        scrollToError(validationErrors)
        return
      }

      setErrors({})
      const formData: CalculatorParamsSchema = {
        address,
        monthlyBill,
        ratePerKw: rentPerKW,
        solar: true,
        battery: {
          capacity: 5000,
          batteriesNumber: batteryCount,
        },
        gpu: {
          cardsNumber: gpuSettings.cardsNumber,
          modelName: gpuSettings.modelName,
          rentHourlyRate: gpuSettings.rentHourlyRate,
          rentProbability: gpuSettings.rentProbability,
        },
        profitSources: profitsSources,
      }
      void sendCalculator(formData)
    },
    [address, monthlyBill, rentPerKW, batteryCount, gpuSettings, profitsSources, scrollToError]
  )

  if (isLoading) {
    return (
      <Oval
        height="8 0"
        width="80"
        color="#506de7"
        secondaryColor="#0033ff"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
      />
    )
  }

  return (
    <CalculatorForm onSubmit={handleSubmit}>
      {isLoaded ? (
        <FieldContainer ref={addressRef}>
          <AddresInput onSelectPlace={handleAddressSelect} isCalculator />
          {errors['address'] && <Error>{errors['address']}</Error>}
        </FieldContainer>
      ) : (
        <Oval
          height="80"
          width="80"
          color="#506de7"
          secondaryColor="#0033ff"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      )}
      <GridWrapper>
        <FieldContainer ref={monthlyBillRef}>
          <AverageElectricBill onBillChange={handleMonthlyBillSelect} value={monthlyBill} isCalculator />
          {errors['monthlyBill'] && <Error>{errors['monthlyBill']}</Error>}
        </FieldContainer>
        <FieldContainer>
          <InputName>Rate per kW</InputName>
          <InputNumber required value={rentPerKW} symbol="$" symbolPosition={25} onChange={handleRentkwChange} />
        </FieldContainer>
      </GridWrapper>
      <FieldContainer>
        <ToggleOption text="Solar" disabled />
      </FieldContainer>
      <FieldContainer>
        <OptionTitle>Battery</OptionTitle>
        <BatteryContainer>
          <NumberOfBatteries>Number of Batteries:</NumberOfBatteries>
          <Counter initialValue={1} onCountChange={handleBatteryChange} />
        </BatteryContainer>
        <StyledSubtitle>Capacity: 10 kwh</StyledSubtitle>
      </FieldContainer>
      <AvaNodeCard gpuModels={gpuModels} gpuSettings={gpuSettings} setGpuSettings={setGpuSettings} />
      <ProfitSources
        rentHourlyRate={gpuSettings.rentHourlyRate}
        rentProbability={gpuSettings.rentProbability}
        profitsSources={profitsSources}
        setProfitsSources={setProfitsSources}
      />
      {isCalculatorLoading ? (
        <Oval
          height="80"
          width="80"
          color="#506de7"
          secondaryColor="#0033ff"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      ) : (
        yearlyProfits && <EstimatedSystem calculatorProfits={yearlyProfits} period={period} setPeriod={setPeriod} />
      )}
      <Container width="200">
        <Button $buttonType="primary" size="xl" text="Calculate" theme="default" type="submit" />
      </Container>
    </CalculatorForm>
  )
}

export default Calculator
